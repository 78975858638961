import _objectSpread from "E:/AirVenture/frontend/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH } from "@/core/services/store/request.module";
import Overview from "@/view/pages/service/detail/Overview";
import Tickets from "@/view/pages/partials/Detail/Tickets";
import Quotations from "@/view/pages/partials/Detail/Quotation";
import Invoices from "@/view/pages/partials/Detail/Invoice";
import Images from "@/view/pages/partials/Detail/Image";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
export default {
  data: function data() {
    return {
      detail: new Object(),
      serviceMoreAction: [{
        title: "Duplicate",
        action: "duplicate",
        icon: "mdi-content-duplicate",
        disabled: false
      }, {
        title: "Mark as Active",
        action: "mark_as_active",
        icon: "mdi-check-all",
        disabled: false
      }, {
        title: "Mark as In-Active",
        action: "mark_as_inactive",
        icon: "mdi-check-all",
        disabled: false
      }],
      tabs: [{
        title: "Overview",
        icon: "mdi-phone",
        key: "service",
        permission: "service",
        disabled: false
      }, {
        title: "Images",
        icon: "mdi-phone",
        key: "image",
        permission: "product",
        disabled: false
      },
      /*{
        title: "Jobs",
        icon: "mdi-credit-card",
        key: "job",
        permission: "job",
        disabled: false,
      },
      {
        title: "Quotations",
        icon: "mdi-account-multiple",
        key: "quotation",
        permission: "quotation",
        disabled: false,
      },
      {
        title: "Invoices",
        icon: "mdi-account-multiple",
        key: "invoice",
        permission: "invoice",
        disabled: false,
      },*/
      {
        title: "History",
        icon: "mdi-account-multiple",
        key: "history",
        permission: "history",
        disabled: false
      }],
      formValid: true,
      formLoading: false,
      pageLoading: false,
      service: 0
    };
  },
  components: {
    DetailTemplate: DetailTemplate,
    Images: Images,
    Overview: Overview,
    Tickets: Tickets,
    Quotations: Quotations,
    Invoices: Invoices,
    InternalHistoryDetail: InternalHistoryDetail
  },
  methods: {
    patchProductParams: function patchProductParams(status) {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(PATCH, {
        url: "product",
        data: {
          status: status,
          products: [_this.service]
        }
      }).then(function () {
        _this.getProductDetail();
      }).catch(function (error) {
        _this.logError(error);
      });
    },
    updateMoreAction: function updateMoreAction(action) {
      var _this = this;

      switch (action) {
        case "duplicate":
          _this.duplicateProduct();

          break;

        case "mark_as_active":
          _this.patchProductParams(1);

          break;

        case "mark_as_inactive":
          _this.patchProductParams(0);

          break;
      }
    },
    duplicateProduct: function duplicateProduct() {
      this.$router.push(this.getDefaultRoute("service.create", {
        query: {
          duplicate: this.service
        }
      }));
    },
    getProductDetail: function getProductDetail() {
      var _this = this;

      _this.pageLoading = true;

      _this.$store.dispatch(GET, {
        url: "service/" + _this.service
      }).then(function (_ref) {
        var data = _ref.data;
        _this.detail = data;

        _this.$store.dispatch(SET_BREADCRUMB, [{
          title: "Service",
          route: "service"
        }, {
          title: "Detail"
        }, {
          barcode: data.barcode
        }]);
      }).catch(function (error) {
        _this.logError(error);
      }).finally(function () {
        _this.pageLoading = false;
      });
    }
  },
  mounted: function mounted() {
    var _this = this;

    _this.getProductDetail();

    _this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Service",
      route: "service"
    }, {
      title: "Detail"
    }]);
  },
  created: function created() {
    var _this = this;

    _this.service = _this.$route.params.id;

    if (!_this.service || _this.service <= 0) {
      _this.$router.go(-1);
    }

    if (_this.$route.query && _this.$route.query.tab) {
      _this.serviceTab = _this.$route.query.tab;
    }
  },
  computed: {
    serviceTab: {
      set: function set(val) {
        var query = _objectSpread({}, this.$route.query);

        query.tab = val;

        if (this.serviceTab != val) {
          this.$router.push({
            query: query
          });
        }
      },
      get: function get() {
        return this.$route.query.tab || "service";
      }
    }
  }
};